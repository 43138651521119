@font-face {
  font-family: 'SofiaPro';
  src: url('../fonts/SofiaPro.woff2') format('woff2'), url('../fonts/SofiaPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SofiaProBold';
  src: url('../fonts/SofiaProBold.woff2') format('woff2'), url('../fonts/SofiaProBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
